<template>
  <div class="LoginForm__Container">
    <div class="LoginForm__Main">
      <div class="LoginForm__Main_Blur" />
      <div class="LoginForm__Logo">
        <img src="../../../assets/logo.png" />
        <!-- <div class="Login__appNames">
          <h2 class="Login__appSubtitle">MONTE NAPOLEONE</h2>
          <h3 class="Login__appName">RETAIL DESIGN PLATFORM</h3>
        </div> -->
      </div>
      <div class="LoginForm__Inputs">
        <LoginField
          class="LoginForm__Field"
          ref="emailFieldRef"
          :input-type="'email'"
          @emailChanged="UpdateEmail($event)"
        />
        <LoginField
          class="LoginForm__Field"
          ref="passwordFieldRef"
          :input-type="'password'"
          @passwordChanged="UpdatePassword($event)"
        />
        <router-link :to="{ name: 'forgotPassword' }">
          <ForgotButton class="ForgotForm__Button" />
        </router-link>
      </div>
      <CTA
        class="LoginForm__Button"
        label="Connect"
        @clickButton="login"
        :color="'#d7321e'"
      />
    </div>
  </div>
</template>

<script>
import CTA from '@/components/button/CTA'
import LoginField from './LoginField'
import ForgotButton from './ForgotButton'

export default {
  components: {
    LoginField,
    ForgotButton,
    CTA
  },
  data: () => ({
    email: '',
    password: '',
    waitForResponse: false
  }),
  methods: {
    async login () {
      const { emailFieldRef: emailRef, passwordFieldRef: pwRef } = this.$refs

      if (emailRef.valid && pwRef.valid) {
        const params = { email: emailRef.content, password: pwRef.content }

        this.$listeners.submit(params)
      } else {
        if (!emailRef.content.length) {
          this.$refs.emailFieldRef.SetEmptyEmailWarning()
        }
        if (!pwRef.content.length) {
          this.$refs.passwordFieldRef.SetEmptyPasswordWarning()
        }
      }
    },
    UpdateEmail (value) {
      this.email = value
    },
    UpdatePassword (value) {
      this.password = value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/main.scss';

.LoginForm__Container {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
}

.LoginForm__Main {
  position: relative;
  box-sizing: border-box;
  padding: 1rem 1.2rem 1.5rem 1.2rem;
  height: 300px;
  min-width: 310px;
  border-radius: 2.5rem;
  background-color: rgba(255, 255, 255, 0.5);
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.LoginForm__Main_Blur {
  border-radius: 2.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  backdrop-filter: blur(10px);
}

.LoginForm__Logo {
  width: 155px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
    margin: 0 auto;
    object-fit: contain;
    box-sizing: border-box;
  }
}

.LoginForm__Inputs {
  height: 50%;
}

.LoginForm__Field {
  height: 35% !important;
}

.LoginForm__Button {
  height: 13%;
  width: 50%;
  margin: 0 auto;
}

.cell {
  background: white;
}

.LoginForm__loginWindow {
  width: 250px;
  align-self: center;
  display: flex;
  flex: none;
  flex-direction: column;
  height: 60%;
  align-items: stretch;
}
.LoginForm__itm {
  display: flex;
  flex: none;
  height: 25%;
  padding: 5% 3%;
  justify-content: center;
}
.LoginForm__loginButton {
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  text-transform: none;
  cursor: pointer;
}
.LoginForm__forgotPasswordButton {
  font-family: 'Roboto', sans-serif;
  font-weight: thin;
  text-transform: none;
  cursor: pointer;
}

.Login__appNames {
  text-align: center;
  margin: 0 auto;
  h2 {
    font-family: 'Gotham Bold';
    color: rgb(215, 50, 30);
    margin: 0.1rem 0;
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 0.5,
      $max_font * 0.7
    );
  }
  h3 {
    letter-spacing: 0.7px;
    @include fluid-type(
      $min_width,
      $max_width,
      $min_font * 0.8,
      $max_font * 0.5
    );
  }
}

.ForgotForm__Button {
  margin: 0 auto;
}
</style>
