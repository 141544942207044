<template>
  <div class="Login__container">
    <Parallax :images="ParallaxImages()" />
    <LoginForm @submit="submit" />
    <div v-if="loading" class="Login__LoadingPage">
      <InfiniteLoader class="Login__Loader" />
    </div>
  </div>
</template>

<script>
import LoginForm from '@/views/login/components/LoginForm.vue'
import InfiniteLoader from '@/components/loader/InfiniteLoader.vue'
// import AdaptativeImage from '@/components/adaptativeImage/AdaptativeImage.vue'
import api from '@/services/api'
import { fetchEvents, fetchCards, setTheme } from '@/services/dataHandler'
import { EventBus } from '@/event-bus.js'
import Parallax from '@/components/parallax/Parallax.vue'

export default {
  name: 'Login',
  data: function () {
    return {
      loading: false
    }
  },
  components: {
    LoginForm,
    InfiniteLoader,
    Parallax
    // AdaptativeImage
  },
  created () {
    // clear localStorage
    localStorage.removeItem('token')
    localStorage.removeItem('events')
    if (
      navigator.userAgent.indexOf('Chrome') === -1 &&
      navigator.userAgent.indexOf('Firefox') === -1
    ) {
      alert(
        'this website is not optimized for this browser, please open this website from the Firefox or Chrome browser for a better experience'
      )
    }
  },
  methods: {
    ParallaxImages () {
      const result = []
      result.push({ src: 'Exterior_03.png', force: 0 })
      result.push({ src: 'Exterior_02.png', force: 10 })
      result.push({ src: 'Exterior_01.png', force: 30 })
      return result
    },
    ParallaxAnimations () {
      const result = []
      result.push({
        src: ['Birds_01.png', 'Birds_02.png', 'Birds_03.png'],
        force: -20
      })
      return result
    },
    async submit (credentials) {
      this.loading = true
      try {
        const res = await api.devices.get(
          credentials.email,
          credentials.password
        )
        const devices = res.data.data
        let found = false
        if (devices) {
          for (let i = 0; i < devices.length; i++) {
            for (let j = 0; j < devices[i].apps.length; j++) {
              if (devices[i].apps[j].app._id === process.env.VUE_APP_ID) {
                found = true
                localStorage.setItem(
                  'events',
                  devices[i].apps[j].app.availableSpecifics
                )
                localStorage.setItem(
                  'token',
                  devices[i].apps[j].appViewer.token
                )
                await fetchEvents()
                await setTheme()
                await fetchCards()
                if (
                  JSON.parse(localStorage.getItem('user-infos')) &&
                  JSON.parse(localStorage.getItem('user-infos')).firstName
                ) {
                  this.$router.push({ name: 'lobby' })
                } else {
                  this.$router.push({ name: 'userPage' })
                }
              }
            }
          }
        }
        if (!found) {
          EventBus.$emit('notify', {
            type: 'error',
            message: 'unauthorized',
            hidden: true
          })
        }
      } catch (e) {
        let error = ''
        console.error(e)
        if (e.response) {
          switch (e.response.status) {
            case 403:
              error = 'unauthorized'
              break
            case 401:
              error = 'bad credentials'
              break
            default:
              error = 'error, contact an administrator'
              break
          }
          if (error !== '') {
            EventBus.$emit('notify', {
              type: 'error',
              message: error,
              hidden: true
            })
          }
        } else {
          EventBus.$emit('notify', {
            type: 'error',
            message: 'offline, please check your connexion',
            hidden: true
          })
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.Login__container {
  position: relative;
  width: 100%;
  height: 100%;
}

.Login__Image_Bg {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.Login__LoadingPage {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
}

.Login__Loader {
  margin: auto;
  width: 5em;
  height: 5em;
}
</style>
